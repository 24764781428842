import { HomeLinksProps } from '../types';

export const homeLinks: HomeLinksProps[] = [
  {
    label: 'Primeiro contato com o sistema',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/primeiro-contato-com-konvix-gestao',
  },
  {
    label: 'Inserir, editar e excluir registros',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/operações-padrão-inserir-editar-e-excluir-um-registro',
  },
  {
    label: 'Criar consulta à um clique',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/cartão-de-consultas-pesquisas-a-um-clique',
  },
  {
    label: 'Gerar relatórios',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/como-gerar-relatórios',
  },
  {
    label: 'Alterar visualização',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/modos-de-visualização',
  },
];
