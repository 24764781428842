import React, { useEffect } from 'react';
import { ActGridContainer, ActRow, Container } from './styles';
import Separator from '~/components/Separator';
import { homeActions } from './utils/home-actions';
import { ButtonAction } from './Components/ButtonAction';
import { BtnUpdateAll } from './Components/BtnUpdateAll';
import { Card } from './Components/DashCard/Card';
import { DashButton } from './Components/DashCard/Button';
import { DashBody } from './Components/DashCard/Body/DashBody';
import { useDashboard } from './hooks/useDashboard';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import useAuth from '~/hooks/useAuth';
import { homeLinks } from './utils/home-links';
import { nanoid } from 'nanoid';

export const DashboardContent: React.FC<{ screen: any[] }> = ({ screen }) => {
  const { loading, handleUpdateDataDashboard, loadingQry } = useDashboard();

  const user = useAuth();

  useEffect(() => {
    handleUpdateDataDashboard('todos');
  }, []);

  return (
    <Container>
      <section className="section-home-links">
        {homeLinks.map((link) => (
          <a
            key={nanoid()}
            href={link.link}
            className="container-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.label}
          </a>
        ))}
      </section>
      <section>
        <Separator labelText="Ações" marginTop="0" />
        <ActGridContainer>
          {Array.from(
            { length: Math.ceil(homeActions.length / 5) },
            (_, index) => (
              <ActRow key={index}>
                {homeActions.slice(index * 5, index * 5 + 5).map((action) => (
                  <ButtonAction
                    key={action.label}
                    label={action.label}
                    href={action.href}
                    icon={action.icon}
                    codTela={action.codTela}
                    user={user}
                  />
                ))}
              </ActRow>
            ),
          )}
        </ActGridContainer>
      </section>
      <section className="section-dashboard">
        <Separator labelText="Dashboard" marginTop="0" childrenWidth="auto">
          <BtnUpdateAll />
        </Separator>
        <div className="content-dashboard">
          <div className="left-side">
            <div className="cards-top-left">
              <Card
                title="A pagar"
                topRightButton={<DashButton tipo="add" url="/contas-a-pagar" />}
                body={<DashBody card="pagar" />}
              />
              <Card
                title="A receber"
                topRightButton={
                  <DashButton tipo="add" url="/contas-a-receber" />
                }
                body={<DashBody card="receber" />}
              />
              <Card
                title="Venda"
                topRightButton={<DashButton tipo="add" url="/pdv-online" />}
                body={<DashBody card="venda" />}
              />
            </div>
            <div className="cards-bottom-left">
              <Card
                title="Venda"
                topChildren={
                  <DashButton tipo="requisito" tipoBusca="venda-grafico" />
                }
                body={<DashBody card="venda-grafico" />}
              />
              <Card
                title="Caixas Hoje"
                topRightButton={
                  <DashButton
                    tipo="refresh"
                    tipoBusca="caixas-hoje"
                    isDisabled={loading.caixas_hoje || loadingQry}
                  />
                }
                body={<DashBody card="caixas-hoje" />}
              />
            </div>
          </div>
          <div className="right-side">
            <Card
              title="Produtos"
              topRightButton={<DashButton tipo="add" url="/produto" />}
              body={<DashBody card="produtos" />}
            />
            <Card
              title="Ocorrências"
              topRightButton={
                <DashButton
                  tipo="refresh"
                  tipoBusca="ocorrencias"
                  isDisabled={loading.ocorrencias || loadingQry}
                />
              }
              topChildren={
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <FaRegCalendarAlt size={19} />
                  <span style={{ marginLeft: '0.3125rem' }}>
                    {format(new Date(), 'dd/MM/yyyy')}
                  </span>
                </div>
              }
              topChildrenMiddle={false}
              body={<DashBody card="ocorrencias" screen={screen} />}
            />
          </div>
        </div>
      </section>
    </Container>
  );
};
